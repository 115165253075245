import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { BackgroundIcon } from '../components/common/BackgroundIcon'
import { showDialog } from '../components/common/Dialog'
import { ViewingMode } from '../data/ViewingMode'

export type ActionProps =
  | {
      // Sign in to ___
      title: string
      // <Red>You'll need to sign in first</Red> to ___
      message?: string
    }
  | {
      // Sign in to ___
      title: string
      // <Red>You'll need to sign in first</Red> to ___
      message: string
    }
  | {
      // Sign in to ___
      title?: string
      // <Red>You'll need to sign in first</Red> to ___
      message: string
    }

export function showSignInToEditDialog(props: {
  action?: ActionProps
  navigateToSignIn: () => void
  isLocalReview?: boolean
}) {
  showDialog({
    title: `Sign in to ${props.action?.title ?? 'make changes'}`,
    positiveButtonProps: {
      text: 'Sign in',
      onClicked: () => {
        props.navigateToSignIn()
        return true
      },
    },
    negativeButtonProps: {
      text: 'No thanks',
      onClicked: () => true,
    },
    children: (Red) => (
      // <>
      //   By default we lock the review to protect against accidental changes.{' '}
      //   <Red>Would you like to start making changes?</Red>
      // </>
      <>
        {props.action && (
          <>
            <Red>You&apos;ll need to sign in</Red> to{' '}
            {props.action.message ?? props.action.title ?? 'make these changes'}.
          </>
        )}
        {!props.action && !props.isLocalReview && (
          <>
            <Red>You&apos;ll need to sign in to make changes</Red> or to edit comments on this
            review.
          </>
        )}
        {!props.action && props.isLocalReview && (
          <>
            <Red>You&apos;ll need to sign in</Red> to make these changes.
          </>
        )}
      </>
    ),
  })
}

export function useSignToEditDialog(
  documentPermissions: DocumentPermissions | undefined,
  isLocalReview: boolean,
  user: User | undefined,
  navigateToSignIn: () => void,
): { showSignInToEditDialog?: (props?: ActionProps) => void } {
  const showSignInToEditDialogInternal = useMemo(() => {
    return !user && documentPermissions === 'edit' ?
        (props?: ActionProps) => {
          showSignInToEditDialog({
            action: props,
            navigateToSignIn,
            isLocalReview,
          })
        }
      : undefined
  }, [user, documentPermissions, navigateToSignIn, isLocalReview])

  return useMemo(
    () => ({
      showSignInToEditDialog: showSignInToEditDialogInternal,
    }),
    [showSignInToEditDialogInternal],
  )
}

export function useSwitchToEditDialog(
  documentPermissions: DocumentPermissions | undefined,
  viewingMode: ViewingMode,
  baseViewingMode: ViewingMode,
  user: User | undefined,
  navigateToSignIn: () => void,
): { showSwitchToEditDialog?: () => void } {
  const showSwitchToEditDialog = useMemo(() => {
    return (
        user &&
          documentPermissions === 'edit' &&
          baseViewingMode === 'view' &&
          baseViewingMode === viewingMode
      ) ?
        () => {
          showDialog({
            title: 'You are currently in View mode',
            positiveButtonProps: {
              text: 'Edit',
              onClicked: () => {
                navigateToSignIn()
                return true
              },
            },
            negativeButtonProps: {
              text: 'No thanks',
              onClicked: () => true,
            },
            children: (Red) => (
              <>
                By default Playback starts in <Red>View mode</Red> to protect against accidental
                changes. <Red>Would you like to start making changes?</Red>
              </>
            ),
          })
        }
      : undefined
  }, [user, baseViewingMode, viewingMode, documentPermissions, navigateToSignIn])

  return useMemo(
    () => ({
      showSwitchToEditDialog,
    }),
    [showSwitchToEditDialog],
  )
}

type PillContainerProps<T extends object | string | number | symbol = any> = {
  defaultColor?: string
  defaultTextColor?: string
  noWrap?: boolean
} & (
  | {
      pills: readonly ManualPillProps[]
    }
  | ({
      pills: readonly UseStatePillProps<T>[]
      id?: (value: T) => string
    } & (
      | {
          useState: [T, Dispatch<SetStateAction<T>>]
        }
      | {
          state: T
          setState: Dispatch<SetStateAction<T>> | Dispatch<T>
        }
    ))
)

type PillPropUIProps = {
  icon?: string | ((isSelected: boolean) => string)
  title: string
  selectedColor?: string
  deselectedColor?: string
  textColor?: string
  // css filter
  filter?: string
  id?: string
}

type ManualPillProps = PillPropUIProps & {
  selected?: boolean
  onClick?: () => void
}

type UseStatePillProps<T extends object | string | number | symbol> = PillPropUIProps & {
  state: T
  onClick?: () => void
}

export function PillContainer<T extends object | string | number | symbol = any>(
  props: PillContainerProps<T>,
) {
  const pills = useMemo(() => {
    return props.pills.map((pill, index) => {
      const selected =
        'useState' in props ? props.useState[0] === props.pills[index].state
        : 'setState' in props ? props.state === props.pills[index].state
        : !!props.pills[index].selected

      const id =
        'useState' in props || 'setState' in props ? props.id?.(props.pills[index].state) : pill.id

      const handleClick = () => {
        if ('useState' in props) {
          props.useState[1](props.pills[index].state)
        } else if ('setState' in props) {
          props.setState(props.pills[index].state)
        }
        pill.onClick?.()
      }

      return (
        <Pill
          key={index}
          deselectedColor={props.defaultColor}
          textColor={props.defaultTextColor}
          {...pill}
          id={id}
          selected={selected}
          onClick={handleClick}
        />
      )
    })
  }, [props])

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: !props.noWrap ? 'wrap' : 'nowrap',
        gap: '8px',
        zoom: isMobile ? '0.9' : '1',
      }}>
      {pills}
    </div>
  )
}

export function Pill({
  selected = false,
  selectedColor = '#DD3D4E',
  title,
  onClick,
  icon,
  filter,
  ...props
}: ManualPillProps) {
  return (
    <div
      id={props.id}
      style={{
        backgroundColor: (selected && selectedColor) || props.deselectedColor || '#353A44',
        padding: '8px 12px',
        position: 'relative',
        display: 'inline-flex',
        borderRadius: '999px',
        fontWeight: 550,
        fontSize: '1em',
        verticalAlign: 'middle',
        fontFamily: 'LeagueSpartan, sans-serif',
        color: props.textColor ?? 'white',
        cursor: 'pointer',
        gap: '8px',
      }}
      onClick={onClick}>
      {icon && (
        <BackgroundIcon
          src={typeof icon === 'string' ? icon : icon(selected)}
          style={{
            display: 'inline-block',
            height: '1em',
            filter,
          }}
        />
      )}
      <span
        className='text-nowrap'
        style={{
          background: 'inherit',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          /* use https://isotropic.co/tool/hex-color-to-css-filter/ */
          filter: 'invert() brightness(2) grayscale(1) contrast(30)',
        }}>
        {title ?? undefined}
      </span>
    </div>
  )
}

import { MutableRefObject, Ref, RefCallback, useCallback } from 'react'

export function useCompositeRefObject<T>(...refs: Ref<T>[]): RefCallback<T> {
  return useCallback(
    (element: T) => compositeRef(...refs)(element),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs,
  )
}

export function compositeRef<T, K extends T>(...refs: Ref<T>[]) {
  return (element: K) => {
    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i]
      if (typeof ref === 'function') ref(element)
      else if (ref && typeof ref === 'object') (ref as MutableRefObject<T>).current = element
    }
  }
}

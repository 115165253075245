import { usePrevious } from '@chakra-ui/react'
import { TouchEvent, useEffect, useMemo, useRef, useState } from 'react'
import { cn } from './common/utils/tailwindUtils'

const isHorizontallyScrollable = (element: HTMLElement): boolean => {
  return element.scrollWidth > element.clientWidth
}

const findScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
  while (element && element !== document.body) {
    if (isHorizontallyScrollable(element)) {
      return element
    }
    element = element.parentElement
  }
  return null
}

export function SnappingThreeItemCarousel<T>(props: {
  className?: string
  render: (props: T, ref: (e: HTMLElement | null) => void) => JSX.Element
  renderPreview?: (props: T, ref: (e: HTMLElement | null) => void) => JSX.Element
  currentItem: T
  nextItem?: T
  previousItem?: T
  onSwipe?: (direction: 'previous' | 'next') => void
}) {
  const renderPreview = props.renderPreview ?? props.render
  const carouselDivRef = useRef<HTMLDivElement>(null)
  const [animation, setAnimation] = useState<{
    prev?: T
    current: T
    next?: T
    direction?: 'left' | 'right'
  }>({
    prev: props.previousItem,
    current: props.currentItem,
    next: props.nextItem,
  })

  const lastCurrentItem = usePrevious(props.currentItem)
  const lastNextItem = usePrevious(props.nextItem)
  const lastPreviousItem = usePrevious(props.previousItem)

  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)

  const handleSwipe = (direction: 'left' | 'right') => {
    if (animation.direction || !props.onSwipe) return

    setAnimation((prev) => ({
      ...prev,
      direction: direction,
    }))

    props.onSwipe(direction === 'left' ? 'next' : 'previous')

    setTimeout(() => {
      setAnimation({
        prev: props.previousItem,
        current: props.currentItem,
        next: props.nextItem,
      })
    }, 300)
  }

  useEffect(() => {
    if (JSON.stringify(props.currentItem) === JSON.stringify(lastCurrentItem)) return

    if (JSON.stringify(props.currentItem) === JSON.stringify(lastNextItem)) {
      setAnimation({
        prev: lastPreviousItem,
        current: lastCurrentItem,
        next: lastNextItem,
        direction: 'left',
      })
    } else if (JSON.stringify(props.currentItem) === JSON.stringify(lastPreviousItem)) {
      setAnimation({
        prev: lastPreviousItem,
        current: lastCurrentItem,
        next: lastNextItem,
        direction: 'right',
      })
    } else {
      return
    }
    const timer = setTimeout(() => {
      setAnimation({
        prev: props.previousItem,
        current: props.currentItem,
        next: props.nextItem,
      })
    }, 300) // Match this with the CSS transition duration
    return () => {
      // clearTimeout(timer)
      // setIsAnimating({
      //   prev: props.previousItem,
      //   current: props.currentItem,
      //   next: props.nextItem,
      // })
    }
  }, [
    props.currentItem,
    lastCurrentItem,
    lastNextItem,
    lastPreviousItem,
    props.previousItem,
    props.nextItem,
  ])

  const prevElementRef = useRef<HTMLElement | null>()
  const prevElement = useMemo(() => {
    const item = animation?.prev ?? props.previousItem
    return item && renderPreview(item, (e) => (prevElementRef.current = e))
  }, [animation?.prev, props.previousItem, renderPreview])

  const currElementRef = useRef<HTMLElement | null>()
  const currElement = useMemo(() => {
    const item = animation?.current ?? props.currentItem
    return item && props.render(item, (e) => (currElementRef.current = e))
  }, [animation, props])

  const nextElementRef = useRef<HTMLElement | null>()
  const nextElement = useMemo(() => {
    const item = animation?.next ?? props.nextItem
    return item && renderPreview(item, (e) => (nextElementRef.current = e))
  }, [animation?.next, props.nextItem, renderPreview])

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    if (!props.onSwipe) return
    const target = e.target as HTMLElement
    const scrollableParent = findScrollableParent(target)
    if (scrollableParent && scrollableParent !== carouselDivRef.current) return
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!props.onSwipe || !touchStart) return
    const target = e.target as HTMLElement
    const scrollableParent = findScrollableParent(target)
    if (scrollableParent && scrollableParent !== carouselDivRef.current) return
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    if (!props.onSwipe || !touchStart || !touchEnd) return
    const target = e.target as HTMLElement
    const scrollableParent = findScrollableParent(target)
    if (scrollableParent && scrollableParent !== carouselDivRef.current) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > 50
    const isRightSwipe = distance < -50
    if (isLeftSwipe) {
      handleSwipe('left')
    }
    if (isRightSwipe) {
      handleSwipe('right')
    }
  }
  return (
    <div
      className={cn(
        'relative box-content flex items-center justify-center overflow-visible',
        props.className,
      )}
      ref={carouselDivRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      {animation.prev && (
        <div
          key={`prev`}
          className={cn(
            'absolute h-fit w-fit -translate-x-[calc(100%+10px)] transform opacity-50',
            animation.direction === 'right' &&
              'translate-x-0 opacity-100 transition-all duration-300 ease-in-out',
            animation.direction === 'left' &&
              '-translate-x-[calc(200%+20px)] opacity-0 transition-all duration-300 ease-in-out',
          )}>
          {prevElement}
        </div>
      )}
      <div
        key={`current`}
        className={cn(
          'h-fit w-fit translate-x-0 transform opacity-100',
          animation.direction === 'right' &&
            'translate-x-[calc(100%+10px)] opacity-50 transition-all duration-300 ease-in-out',
          animation.direction === 'left' &&
            '-translate-x-[calc(100%+10px)] opacity-50 transition-all duration-300 ease-in-out',
        )}>
        {currElement}
      </div>
      {animation.next && (
        <div
          key={`next`}
          className={cn(
            'absolute h-fit w-fit translate-x-[calc(100%+10px)] transform opacity-50',
            animation.direction === 'right' &&
              'translate-x-[calc(200%+20px)] opacity-0 transition-all duration-300 ease-in-out',
            animation.direction === 'left' &&
              'translate-x-0 opacity-100 transition-all duration-300 ease-in-out',
          )}>
          {nextElement}
        </div>
      )}
    </div>
  )
}

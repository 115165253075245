import { Flex, Image } from '@chakra-ui/react'
import { cn } from 'components/common/utils/tailwindUtils'
import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import React, { CSSProperties, ReactNode, forwardRef, useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import ReactTagManager from 'react-ga4'
import { EventDefinition } from 'templates/TemplateConfig'
import { showPlayerChooser } from '../components/PlayerChooser'
import { InlineShortcutIcon } from '../components/ShortcutIcon'
import { LOCAL_USER } from '../components/Timeline'
import { EditableStringDiv } from '../components/common/EditableStringDiv'
import { PwaInstaller } from '../components/common/InstallPwa'
import { RoundButton } from '../components/common/RoundButton'
import { useSingleAndDoubleClick } from '../components/common/UseSingleAndDoubleClick'
import { NotificationRegisterer } from '../components/common/useNotificationsRegistration'
import { Player } from '../data/Player'
import { TimelineNote } from '../data/TimelineEvent'
import { ViewingMode } from '../data/ViewingMode'
import editButton from '../icons/edit_button_black.png'
import replyIcon from '../icons/reply_button.png'
import { ActionProps } from '../util/dialogUtils'
import { CommentTopInteractionsBar } from './CommentTopInteractionsBar'
import { ReplyBar } from './ReplyBar'
import { TimelineStore } from './TimelineStore'

export type ClipCardProps = {
  isLocalReview: boolean
  isSelected: boolean
  isHovered: boolean
  showSignInToEditDialog: undefined | ((props?: ActionProps) => void)
  timelineStore?: TimelineStore
  user: User | undefined
  timelineEvent: TimelineNote<EventDefinition>
  canEnableEditing: boolean
  editableEnabled: boolean
  documentPermissions?: DocumentPermissions
  onClick?: () => void
  onDblClick?: () => void
  onPlayButtonClicked: () => void
  onShowSketchButtonClicked: () => void
  onShareButtonClicked?: () => void
  fontSize: string
  playerIconSize: number
  hideCommentTopInteractionsBar?: boolean
  seen: boolean
  containerRef: React.RefObject<HTMLDivElement>
  className?: string
  style?: CSSProperties
  editableStringClassName?: string
  authorClassName?: string
  children?: ReactNode
  showReply: boolean
  onReplyButtonClicked?: (timelineEvent: TimelineNote<EventDefinition>) => void
}

export const ClipCard = forwardRef<HTMLDivElement, ClipCardProps>(function ClipCard(
  {
    showReply,
    onReplyButtonClicked,
    isLocalReview,
    timelineEvent,
    timelineStore,
    onClick,
    fontSize,
    playerIconSize,
    onDblClick,
    onShareButtonClicked,
    onPlayButtonClicked,
    onShowSketchButtonClicked,
    canEnableEditing,
    editableEnabled,
    user,
    showSignInToEditDialog,
    isSelected,
    isHovered: isExternallyHovered,
    hideCommentTopInteractionsBar,
    seen,
    containerRef,
    className,
    style,
    editableStringClassName,
    authorClassName,
    children,
  }: ClipCardProps,
  ref,
) {
  const handleSubmit = useCallback(
    (changedValue: string) => {
      if (!canEnableEditing) {
        return
      }

      if (!(user || isLocalReview)) {
        showSignInToEditDialog?.()
        return
      }

      if (changedValue.trim() !== timelineEvent.extra?.message?.trim()) {
        timelineStore?.updateEvent(
          {
            ...timelineEvent,
            extra: { ...timelineEvent.extra, message: changedValue.trim() },
          },
          isLocalReview,
          user,
        )
      }
    },
    [timelineStore, timelineEvent, showSignInToEditDialog, canEnableEditing, user, isLocalReview],
  )
  const contentRef = useRef<EditableStringDiv>(null)

  const handlePlayerDblClick = useCallback(
    (originalPlayer: Player) => {
      if (canEnableEditing) {
        if (user || isLocalReview) {
          showPlayerChooser({
            reason: {
              type: 'AssignToTimelineEvent',
              eventDefinition: timelineEvent.tag,
              time: timelineEvent.time,
            },
            location: containerRef.current?.getBoundingClientRect(),
            displayStyle: 'above',
            team: timelineEvent.team,
            time: timelineEvent.time,
            onChoose: (player) => {
              if (player)
                if (player.id === originalPlayer.id) {
                  timelineStore?.updateEvent(
                    {
                      ...timelineEvent,
                      who: (timelineEvent.who ?? []).filter((p) => p.id !== originalPlayer.id),
                    },
                    isLocalReview,
                    user,
                  )
                } else {
                  timelineStore?.updateEvent(
                    {
                      ...timelineEvent,
                      who: (timelineEvent.who ?? [])
                        .filter((p) => p.id !== originalPlayer.id)
                        .concat(player),
                    },
                    isLocalReview,
                    user,
                  )
                }
            },
          })
        } else {
          showSignInToEditDialog?.()
        }
      }
    },
    [
      canEnableEditing,
      user,
      isLocalReview,
      timelineEvent,
      containerRef,
      timelineStore,
      showSignInToEditDialog,
    ],
  )
  const handlePointerOver = useCallback(
    (event: React.PointerEvent) => {
      if (event.pointerType === 'touch') return

      timelineStore?.setSelectedEvents((selections) =>
        selections
          .filter((s) => s.id !== timelineEvent.id || !s.hovered)
          .concat([
            {
              id: timelineEvent.id,
              hovered: true,
            },
          ]),
      )
    },
    [timelineEvent.id, timelineStore],
  )

  const handlePointerOut = useCallback(
    (event: React.PointerEvent) => {
      if (event.pointerType === 'touch') return
      if (!contentRef.current?.hasFocus())
        timelineStore?.setSelectedEvents((selections) =>
          selections.filter((s) => s.id !== timelineEvent.id || !s.hovered),
        )
    },
    [timelineEvent.id, timelineStore],
  )
  const handleBlur = useCallback(() => {
    timelineStore?.setSelectedEvents((selections) =>
      selections.filter((s) => s.id !== timelineEvent.id || !s.hovered),
    )
  }, [timelineEvent.id, timelineStore])

  const singleAndDoubleClick = useSingleAndDoubleClick({
    actionSingleClick: () => {
      if (contentRef.current?.hasFocus()) return // do nothing
      if (timelineStore?.selectedEvents.find((e) => e.id === timelineEvent.id && !e.hovered)) {
        timelineStore?.setSelectedEvents((selections) =>
          selections.filter((s) => s.id !== timelineEvent.id || s.hovered),
        )
      } else {
        timelineStore?.setSelectedEvents?.([{ id: timelineEvent.id, hovered: false }])
      }
      onClick?.()
    },
    actionDoubleClick: () => {
      if (contentRef.current?.hasFocus()) return // do nothing
      if (canEnableEditing) {
        timelineStore?.setSelectedEvents([{ id: timelineEvent.id, hovered: true }])
        if (!user && !isLocalReview) {
          showSignInToEditDialog?.()
        } else if (isLocalReview && timelineEvent.createBy.uid !== LOCAL_USER.uid) {
          showSignInToEditDialog?.({
            title: 'edit',
            message: "edit another user's comment",
          })
        }
      } else {
        timelineStore?.setSelectedEvents([{ id: timelineEvent.id, hovered: false }])
      }

      onDblClick?.()
    },
    actionLongPress: () => {
      if (contentRef.current?.hasFocus()) return // do nothing
      timelineStore?.setSelectedEvents([{ id: timelineEvent.id, hovered: false }])
    },
  })
  const handleAddPlayerClick = useCallback(
    (event: React.MouseEvent) => {
      if (canEnableEditing) {
        if (user || isLocalReview) {
          showPlayerChooser({
            reason: {
              type: 'AssignToTimelineEvent',
              eventDefinition: timelineEvent.tag,
              time: timelineEvent.time,
            },
            location: event.currentTarget.getBoundingClientRect(),
            displayStyle: 'above',
            team: timelineEvent.team,
            time: timelineEvent.time,
            onChoose: (player) => {
              if (player) {
                let who = timelineEvent.who?.slice() ?? []
                if (who.any((it) => it.id === player.id)) {
                  who = who.distinct()
                  who.remove(player)
                  timelineStore?.updateEvent(
                    {
                      ...timelineEvent,
                      who: who,
                    },
                    isLocalReview,
                    user,
                  )
                } else {
                  timelineStore?.updateEvent(
                    {
                      ...timelineEvent,
                      who: who.concat(player).distinct(),
                    },
                    isLocalReview,
                    user,
                  )
                }
              }
            },
          })
        } else {
          showSignInToEditDialog?.()
        }
      }
    },
    [showSignInToEditDialog, canEnableEditing, user, timelineEvent, timelineStore, isLocalReview],
  )

  const handleReplyEmojiClicked = useCallback(
    (reply: string) => {
      if (!canEnableEditing) {
        return
      }
      if (!user) {
        showSignInToEditDialog?.({
          title: 'react',
          message: 'react to a comment',
        })
        return
      }

      const userReplies = Object.values(timelineEvent.replies).filter(
        (it) => it.createBy?.uid === user.uid,
      )

      if (userReplies.length) {
        // timelineStore?.removeReplies(
        //   timelineEvent.id,
        //   userReplies.map((it) => it.id),
        //   user,
        // )
        if (userReplies.any((it) => it.message === reply)) {
          timelineStore?.removeReplies(
            timelineEvent.id,
            userReplies.filter((it) => it.message === reply).map((it) => it.id),
            user,
          )
        }
      }

      if (userReplies.none((it) => it.message === reply)) {
        timelineStore?.addReply(timelineEvent.id, reply, user)
      }
    },
    [timelineStore, timelineEvent, canEnableEditing, showSignInToEditDialog, user],
  )

  const isCommentingPermissions = canEnableEditing
  const showShareButton = true
  // const showShareButton = isExternallyHovered || isSelected || isMobile || isHovered

  return (
    <div
      ref={ref}
      className={cn('pointer-events-auto relative', className)}
      style={style}>
      {!hideCommentTopInteractionsBar && (
        <CommentTopInteractionsBar
          isCommentingPermissions={isCommentingPermissions}
          onAddPlayerClick={handleAddPlayerClick}
          onShowSketchButtonClicked={onShowSketchButtonClicked}
          onShareButtonClicked={onShareButtonClicked}
          onPlayerDblClick={handlePlayerDblClick}
          playerIconSize={playerIconSize}
          fontSize={fontSize}
          onPlayButtonClicked={onPlayButtonClicked}
          showShareButton={showShareButton}
          timelineEvent={timelineEvent}
          editMode={editableEnabled}
          canEnableEditing={canEnableEditing}
          className='-mb-4'
        />
      )}
      <div
        className={cn(
          'pointer-events-none absolute w-full text-center font-league-spartan text-white/30 font-light',
          authorClassName,
        )}
        style={{ fontSize: fontSize, zoom: 0.8 }}>
        {timelineEvent.modifiedBy.length ?
          <>
            by{' '}
            <span style={{ color: 'rgb(255,255,255,0.4)', fontWeight: 400 }}>
              {[timelineEvent.createBy]
                .concat(timelineEvent.modifiedBy)
                // .filter((it, index, array) => array.getOrNull(index - 1)?.uid !== it?.uid)
                .mapNotNull((it) => it.displayName?.trim() || undefined)
                .distinct()
                .join(', ')}
            </span>
          </>
        : undefined}
      </div>
      <EditableStringDiv
        ref={contentRef}
        onPointerDown={singleAndDoubleClick.onDown}
        onPointerUp={singleAndDoubleClick.onUp}
        onPointerMove={singleAndDoubleClick.onMove}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        onBlur={handleBlur}
        className={cn(
          'whitespace-pre-wrap break-words rounded-2xl bg-[#353a44] p-5 leading-relaxed text-[#dcdcdc]',
          isSelected &&
            (isExternallyHovered ?
              'outline outline-2 outline-[rgba(255,255,255,0.2)]'
            : 'outline outline-2 outline-[rgba(255,255,255,0.5)]'),
          editableStringClassName,
          !seen && 'bg-[#4e5a8f]',
        )}
        onSubmit={handleSubmit}
        placeholderStyle={{
          color: isCommentingPermissions ? 'rgb(241,191,70)' : 'rgb(101,101,101)',
          textAlign: 'center',
        }}
        translate={'no'}
        disabled={!editableEnabled}
        blurOnEnter={!isMobile}
        placeholder={isCommentingPermissions ? 'Double click to\nAdd a note' : 'Empty comment'}
        editOn={'dblclick'}>
        {timelineEvent?.isDeleted ? 'deleted message' : timelineEvent.extra?.message}
      </EditableStringDiv>
      <ReplyBar
        replies={timelineEvent.replies}
        user={user}
        className='-mt-3'
        onClick={handleReplyEmojiClicked}
        allowReplying={canEnableEditing}>
        <div className='flex flex-col gap-2 items-center'>
          {showReply && onReplyButtonClicked && (
            <ReplyButton
              onClick={() => {
                if (canEnableEditing && !user) {
                  showSignInToEditDialog?.({ title: 'reply', message: 'add a reply' })
                  return
                }
                onReplyButtonClicked?.(timelineEvent)
              }}
            />
          )}
          {!seen && (
            <span
              className={
                'font-[550] w-fit rounded-full bg-[#1f26a9] px-2 py-1 text-center uppercase text-white'
              }
              style={{
                fontFamily: 'LeagueSpartan, sans-serif',
              }}>
              New
            </span>
          )}
        </div>
      </ReplyBar>
      {children}
    </div>
  )
})

export function InstructionCard({
  user,
  viewingMode,
  documentPermissions,
  navigateToSignIn,
  enterEditMode,
  baseViewingMode,
  isLocalReview,
  notificationRegisterer,
  pwaInstaller,
}: {
  pwaInstaller: PwaInstaller | undefined
  notificationRegisterer: NotificationRegisterer
  navigateToSignIn: () => void
  enterEditMode?: (user: User) => void
  user: User | undefined
  baseViewingMode: ViewingMode
  viewingMode: ViewingMode
  documentPermissions?: DocumentPermissions
  isLocalReview: boolean
}) {
  const handleEnterEditMode = useCallback(() => {
    if (!user) {
      return
    }
    ReactTagManager.event('vertical_comment_panel_edit_mode_clicked', {
      category: 'edit_mode',
    })
    enterEditMode?.(user)
  }, [user, enterEditMode])

  const handleSignClicked = useCallback(() => {
    ReactTagManager.event('vertical_comment_panel_sign_in_clicked', {
      category: 'signin',
    })
    navigateToSignIn()
  }, [navigateToSignIn])

  const handleInstallTheAppClicked = useCallback(() => {
    if (pwaInstaller?.installPwa) {
      ReactTagManager.event('vertical_comment_panel_install_app_clicked', {
        category: 'app_install',
      })
      pwaInstaller.installPwa()
    }
  }, [pwaInstaller])

  let mode: 'press-y' | 'edit-only' | 'enter-edit' | 'sign-in' | 'sign-in-local' | undefined
  if (viewingMode === 'edit' && user) {
    mode = 'press-y'
  } else if (baseViewingMode === 'edit' && user) {
    mode = 'edit-only'
  } else if (documentPermissions === 'edit' && user) {
    mode = 'enter-edit'
  } else if (documentPermissions === 'edit' && !user && isLocalReview) {
    mode = 'sign-in-local'
  } else if (documentPermissions === 'edit' && !user) {
    mode = 'sign-in'
  }

  return (
    <div className='flex flex-col items-center justify-center gap-2 text-white'>
      {mode === 'press-y' && (
        <div
          className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#454034] p-3 leading-relaxed
          text-[#b0b0b0]`}
          style={{
            fontFamily: 'LeagueSpartan, sans-serif',
          }}>
          Press{' '}
          <InlineShortcutIcon
            shortcut={'Y'}
            fontSize={'10px'}
            quickKey={true}
          />{' '}
          to add a bookmark and create a comment on this review.
          <br />
          <br />
          Double-click to Edit comments.
        </div>
      )}
      {mode === 'edit-only' && (
        <div
          className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#454034] p-3 leading-[1.2em]
          text-[#b0b0b0]`}
          style={{
            fontFamily: 'LeagueSpartan, sans-serif',
          }}>
          Double-click to Edit comments.
        </div>
      )}
      {mode === 'enter-edit' && (
        <div
          className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#454034] p-3 leading-[1.2em]
          text-[#b0b0b0]`}
          style={{
            fontFamily: 'LeagueSpartan, sans-serif',
          }}>
          Double-click to Edit comments.
          <br />
          <br />
          To add a bookmark, enter edit mode.
          <RoundButton
            alt={'Edit Review'}
            icon={editButton}
            className='m-2'
            onClick={handleEnterEditMode}>
            Edit Review
          </RoundButton>
        </div>
      )}
      {mode === 'sign-in' && (
        <div
          className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#454034] p-3 leading-[1.2em]
          text-[#b0b0b0]`}
          style={{
            fontFamily: 'LeagueSpartan, sans-serif',
          }}>
          Sign in to react or leave a comment on this review.
          <RoundButton
            alt={'Sign in'}
            className='m-2 justify-center'
            onClick={handleSignClicked}>
            Sign in
          </RoundButton>
        </div>
      )}
      {mode === 'sign-in-local' && (
        <div
          className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#454034] p-3 leading-[1.2em]
          text-[#b0b0b0]`}
          style={{
            fontFamily: 'LeagueSpartan, sans-serif',
          }}>
          Sign in to react to comments and keep track of all your reviews.
          <RoundButton
            alt={'Sign in'}
            className='m-2 justify-center'
            onClick={handleSignClicked}>
            Sign in
          </RoundButton>
        </div>
      )}
      {pwaInstaller?.installPwa &&
        (notificationRegisterer.notificationRegistrationState === 'Not supported' ||
          notificationRegisterer.notificationRegistrationState === 'Ready') &&
        !pwaInstaller?.appInstallState && (
          <Flex
            direction={'column'}
            className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#282c34] p-3 leading-[1.2em]
            text-[#b0b0b0]`}
            style={{
              fontFamily: 'LeagueSpartan, sans-serif',
            }}>
            Install the PlayBack App{' '}
            {mode === 'press-y' || mode === 'edit-only' || mode === 'enter-edit' ?
              `to be notified for new team comments.`
            : `for quick access`}
            <br />
            <Image
              alignSelf={'center'}
              src={'/android-chrome-192x192.png'}
              width={80}
              marginTop={20}
              filter={'drop-shadow(0 0 10px rgb(255,255,255,0.2))'}
              onClick={handleInstallTheAppClicked}
            />
            <br />
            <RoundButton
              alt={'Install Playback'}
              className='m-2 justify-center bg-[#DD3D4E] text-white'
              onClick={handleInstallTheAppClicked}>
              Install
            </RoundButton>
          </Flex>
        )}
      {pwaInstaller?.appInstallState && pwaInstaller.appDisplayMode !== 'standalone' && (
        <Flex
          direction={'column'}
          className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#282c34] p-3 leading-relaxed
          text-[#b0b0b0]`}
          style={{
            fontFamily: 'LeagueSpartan, sans-serif',
          }}>
          {pwaInstaller?.appInstallState === 'Installed_In_Session' ?
            `Once the app is installed you can launch it from here.`
          : pwaInstaller?.appInstallState === 'Installed' ?
            `You have the playback app installed. Launch it here.`
          : `The Playback App is installing...`}
          <br />
          {pwaInstaller?.appInstallState !== 'Installing' && (
            <a
              // className={"round-button"}
              href={window.location.toString()}
              className='m-2 justify-center bg-[#DD3D4E] text-white'
              target='_blank'
              rel='noreferrer'>
              Launch
            </a>
          )}
        </Flex>
      )}
      {(mode === 'press-y' || mode === 'edit-only' || mode === 'enter-edit') &&
        notificationRegisterer.notificationRegistrationState !== 'Not supported' && (
          <div
            className={`max-w-[200px] whitespace-pre-wrap break-words rounded-md bg-[#282c34] p-3 leading-relaxed
            text-[#b0b0b0]`}
            style={{
              fontFamily: 'LeagueSpartan, sans-serif',
            }}>
            {notificationRegisterer.notificationRegistrationState === 'Needs Permissions' &&
              `You'll need to grant permissions to be notified when your team leaves comments.`}
            {notificationRegisterer.notificationRegistrationState === 'Pending' &&
              `We are registering your browser for notifications`}
            {notificationRegisterer.notificationRegistrationState === 'AwaitingRestart' &&
              `We are registering your browser for notifications. You'll need to reload.`}
            {notificationRegisterer.notificationRegistrationState === 'Error' &&
              `There was an issue registering for notifications.`}
            {notificationRegisterer.notificationRegistrationState === 'Ready' &&
              `You will receive Notifications for comments on this review. 👍`}
            {notificationRegisterer.notificationRegistrationState !== 'Ready' && (
              <RoundButton
                alt={'Grant Notifications permissions'}
                className='m-2 justify-center !bg-[#DD3D4E] !text-white aria-disabled:opacity-20'
                aria-disabled={notificationRegisterer.notificationRegistrationState === 'Pending'}
                onClick={() =>
                  notificationRegisterer.notificationRegistrationState === 'AwaitingRestart' ?
                    window.location.reload()
                  : notificationRegisterer.requestForNotifications()
                }>
                {notificationRegisterer.notificationRegistrationState === 'Error' ?
                  'Try again'
                : notificationRegisterer.notificationRegistrationState === 'AwaitingRestart' ?
                  'Reload'
                : 'Grant'}
              </RoundButton>
            )}
          </div>
        )}
    </div>
  )
}

export function ReplyButton({ onClick, className }: { className?: string; onClick: () => void }) {
  return (
    <RoundButton
      className={cn('h-[20px] w-fit', className)}
      icon={replyIcon}
      alt='reply'
      onClick={onClick}>
      Reply
    </RoundButton>
  )
}
